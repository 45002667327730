// import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client"

// const API_URL = process.env.NEXT_PUBLIC_API_URL || "https://cms.trustit-co.com/api/content/arafasapps"

// const httpLink = createHttpLink({
//   uri: `${API_URL}/graphql`,
// });

// const client = new ApolloClient({
//   link: httpLink,
//   cache: new InMemoryCache()
// });

import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

export const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://cms.trustit-co.com/api/content/arafasapps/graphql',
    fetch,
  }),
  cache: new InMemoryCache()
});

export default client

// import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
// import { setContext } from '@apollo/client/link/context';

// const API_URL = process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"
// const JWT = process.env.JWT || "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjIxMTc3MDg4LCJleHAiOjE2MjM3NjkwODh9.AwKAhDTck2w0sfUb6CUf2lM3cXKSdJhNQcKZpbHASGc"

// const httpLink = createHttpLink({
//   uri: `${API_URL}/graphql`,
// });

// const authLink = setContext((_, { headers }) => {
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: `Bearer ${JWT}`,
//     }
//   }
// });

// const client = new ApolloClient({
//   link: authLink.concat(httpLink),
//   cache: new InMemoryCache()
// });

// export default client;
