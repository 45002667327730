module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Arafas","short_name":"Arafas","description":"The past talks to the future","start_url":"/","pathPrefix":"/react/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9216c0bad8fa99fcd19c74c961b51206"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
